import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppVersion } from '../_models/index';
import { CommonControllerService } from 'generated';

@Injectable()
export class CommonService {
  constructor(private commonService: CommonControllerService) {}

  version(): Observable<AppVersion> {
    return this.commonService
      .getVersionUsingGET()
      .pipe(map(response => (response as unknown) as AppVersion));
  }
}
