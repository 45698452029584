import { MenuType, RouteInfo } from './navbar.metadata';

export const ROUTES: RouteInfo[] = [
  { name: 'About', href: '/about', needAuthentication: false },
  {
    name: 'Login',
    href: '/login',
    iconClass: 'fa-sign-in fa-fw',
    needAuthentication: false,
  },
  {
    name: 'Account',
    href: '/account',
    iconClass: 'fa-pencil fa-fw',
    needAuthentication: true,
  },
  {
    name: 'Users',
    href: '/users',
    iconClass: 'fa-users fa-fw',
    needAuthentication: true,
  },
  {
    name: 'Logout',
    href: '/login',
    iconClass: 'fa-sign-out fa-fw',
    needAuthentication: true,
  },
];
