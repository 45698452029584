import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { User } from '../_models/index';
import { AuthenticationControllerService } from 'generated';

@Injectable()
export class UserService {
  constructor(private authenticationService: AuthenticationControllerService) {}

  getAll(filter?: string): Observable<User[]> {
    return this.authenticationService
      .getAllUsersUsingGET(filter)
      .pipe(
        map(response => ((response as unknown) as { users: User[] }).users)
      );
  }

  getCurrentUser() {
    return this.authenticationService
      .getAuthenticatedUserDetailsUsingGET()
      .pipe(map(response => ((response as unknown) as { user: User }).user));
  }

  create(user: User) {
    return this.authenticationService
      .createUserUsingPUT(user)
      .pipe(map(response => ((response as unknown) as { user: User }).user));
  }

  updateOwn(user: User) {
    return this.authenticationService
      .updateOwnUserUsingPOST(user)
      .pipe(map(response => ((response as unknown) as { user: User }).user));
  }

  update(username: string, user: User) {
    return this.authenticationService
      .updateUserUsingPOST(username, user)
      .pipe(map(response => ((response as unknown) as { user: User }).user));
  }

  delete(user: User) {
    return this.authenticationService
      .deleteUserUsingDELETE(user.username)
      .pipe(map(response => (response.success as unknown) as boolean));
  }
}
