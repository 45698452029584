/**
 * Application API
 * This site provides an overview of all endpoints (API)
 *
 * The version of the OpenAPI document: 1.1.0-SNAPSHOT-0
 * Contact: info@lufthansa-industry-solutions.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApplicationUserDetailsInput } from '../model/applicationUserDetailsInput';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { AuthenticationControllerServiceInterface }                            from './authenticationController.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationControllerService implements AuthenticationControllerServiceInterface {

    protected basePath = 'https://localhost:8443/app';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createAuthenticationToken
     * 
     * @param username 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAuthenticationTokenUsingPOST(username: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public createAuthenticationTokenUsingPOST(username: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public createAuthenticationTokenUsingPOST(username: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public createAuthenticationTokenUsingPOST(username: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createAuthenticationTokenUsingPOST.');
        }
        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling createAuthenticationTokenUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (username !== undefined) {
            formParams = formParams.append('username', <any>username) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }

        return this.httpClient.post<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/login`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createUser
     * 
     * @param applicationUserDetailsInput user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUserUsingPUT(applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public createUserUsingPUT(applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public createUserUsingPUT(applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public createUserUsingPUT(applicationUserDetailsInput: ApplicationUserDetailsInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (applicationUserDetailsInput === null || applicationUserDetailsInput === undefined) {
            throw new Error('Required parameter applicationUserDetailsInput was null or undefined when calling createUserUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/user`,
            applicationUserDetailsInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteUser
     * 
     * @param username username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserUsingDELETE(username: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public deleteUserUsingDELETE(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public deleteUserUsingDELETE(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public deleteUserUsingDELETE(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteUserUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/user/${encodeURIComponent(String(username))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllUsers
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUsersUsingGET(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public getAllUsersUsingGET(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public getAllUsersUsingGET(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public getAllUsersUsingGET(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAuthenticatedUserDetails
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAuthenticatedUserDetailsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public getAuthenticatedUserDetailsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public getAuthenticatedUserDetailsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public getAuthenticatedUserDetailsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * refreshAndGetAuthenticationToken
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshAndGetAuthenticationTokenUsingGET(observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public refreshAndGetAuthenticationTokenUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public refreshAndGetAuthenticationTokenUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public refreshAndGetAuthenticationTokenUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/refresh`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateOwnUser
     * 
     * @param applicationUserDetailsInput user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOwnUserUsingPOST(applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public updateOwnUserUsingPOST(applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public updateOwnUserUsingPOST(applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public updateOwnUserUsingPOST(applicationUserDetailsInput: ApplicationUserDetailsInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (applicationUserDetailsInput === null || applicationUserDetailsInput === undefined) {
            throw new Error('Required parameter applicationUserDetailsInput was null or undefined when calling updateOwnUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/user`,
            applicationUserDetailsInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateUser
     * 
     * @param username username
     * @param applicationUserDetailsInput user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserUsingPOST(username: string, applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: object; }>;
    public updateUserUsingPOST(username: string, applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: object; }>>;
    public updateUserUsingPOST(username: string, applicationUserDetailsInput: ApplicationUserDetailsInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: object; }>>;
    public updateUserUsingPOST(username: string, applicationUserDetailsInput: ApplicationUserDetailsInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateUserUsingPOST.');
        }
        if (applicationUserDetailsInput === null || applicationUserDetailsInput === undefined) {
            throw new Error('Required parameter applicationUserDetailsInput was null or undefined when calling updateUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<{ [key: string]: object; }>(`${this.configuration.basePath}/rest/security/user/${encodeURIComponent(String(username))}`,
            applicationUserDetailsInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
