import { Component, OnInit } from '@angular/core';

import { User } from '../_models/index';
import { UserService, AppService } from '../_services/index';

@Component({
  moduleId: module.id,
  templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit {
  currentUser: User;
  salutation: string;

  constructor(
    private userService: UserService,
    private appService: AppService
  ) {
    this.loadCurrentUser();
    this.loadWelcomeMessage();
  }

  ngOnInit() {}

  /**
   * Callback handler for 401 unauthorized
   */
  private onErrorCallback = function(error) {
    const message = error;
    const apiError = error.error;

    // Check error message from API response
    if (apiError) {
      if (
        (apiError['http-status'] && apiError['http-status'] === 401) ||
        (apiError instanceof ProgressEvent && apiError['type'] === 'error')
      ) {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
      }
    }

    this.alertService.error(message);
  };

  /**
   * Load the solution for a logged-in user
   */
  private loadWelcomeMessage() {
    this.appService.sayHello().subscribe(
      data => {
        this.salutation = data;
      },
      error => this.onErrorCallback
    );
  }

  /**
   * Load user object for the current authorized user
   */
  private loadCurrentUser() {
    this.userService.getCurrentUser().subscribe(
      user => {
        this.currentUser = user;
      },
      error => this.onErrorCallback
    );
  }
}
