import { Component, OnInit } from '@angular/core';

import { User, AppVersion } from '../_models/index';
import { CommonService } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'about.component.html'
})

export class AboutComponent implements OnInit {
    currentUser: User;
    users: User[] = [];
	salutation: String;
	applicationVersion: AppVersion;

    constructor(private commonService: CommonService) {
    	this.loadApplicationVersion();
    }

    ngOnInit() {
    }

    /**
     * Load application backend version
     */
    private loadApplicationVersion() {
    	this.commonService.version().subscribe((data: AppVersion) => this.applicationVersion = data);
    }
}
