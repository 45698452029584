import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { User } from '../_models/index';
import { BasicValidators } from '../_helpers/index';
import {
  AlertService,
  UserService,
  AuthenticationService,
} from '../_services/index';

@Component({
  moduleId: module.id,
  // selector: 'account-view',
  templateUrl: 'account.component.html',
})
export class AccountComponent implements OnInit {
  form: FormGroup;

  validationMessages = {
    firstname: {
      required: 'You need to provide a firstname.',
      minlength: 'Firstname must be at least 4 characters long.',
    },
    lastname: {
      required: 'You need to provide a lastname.',
      minlength: 'Lastname must be at least 4 characters long.',
    },
    email: {
      required: 'You need to provide a email.',
    },
    password: {
      required: 'You need to provide a password.',
    },
  };

  private _isLoading = false;
  private _currentUser: User;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadUser();

    this.form = this.formBuilder.group({
      firstname: ['', [Validators.required, Validators.minLength(4)]],
      lastname: ['', [Validators.required, Validators.minLength(4)]],
      email: ['', [Validators.required, BasicValidators.email]],
      password: [''],
    });
  }

  set isLoading(loading: boolean) {
    this._isLoading = loading;
  }

  get isLoading() {
    return this._isLoading;
  }

  submitForm(): void {
    this.isLoading = true;

    const updatedUser: User = {
      ...this._currentUser,
      firstname: this.form.get('firstname').value,
      lastname: this.form.get('lastname').value,
      email: this.form.get('email').value,
      username: this.form.get('email').value,
      password: this.form.get('password').value,
    };

    this.updateUser(updatedUser)
      .pipe(take(1))
      .subscribe(
        result => {
          this.isLoading = false;
          this.authenticationService.logout();
          this.router.navigate(['/login']);
          this.alertService.success('Update successful. Please login again.');
        },
        error => {
          this.isLoading = false;
        }
      );
  }

  private loadUser() {
    this.isLoading = true;
    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe(
        user => {
          this.isLoading = false;
          this._currentUser = user;
          this.form.get('firstname').setValue(user.firstname);
          this.form.get('lastname').setValue(user.lastname);
          this.form.get('email').setValue(user.email);
        },
        error => {
          this.isLoading = false;
        }
      );
  }

  private updateUser(user: User) {
    return this.userService.updateOwn(user);
  }
}
