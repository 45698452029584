import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ROUTES } from './navbar-routes.config';
import { MenuType } from './navbar.metadata';
import { AuthenticationService } from '../_services/index';

@Component({
  moduleId: module.id,
  selector: 'navbar',
  templateUrl: 'navbar.component.html',
  // styleUrls: [ 'navbar.component.css' ],
})
export class NavbarComponent implements OnInit {
	public menuItems: any[];

  	isCollapsed = true;

  	constructor(
		  private authenticationService: AuthenticationService) {
  	}

	ngOnInit() {
		// this.menuItems = ROUTES.filter(menuItem => menuItem.menuType !==
		// MenuType.BRAND);
		this.menuItems = ROUTES;
	}
}