﻿import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from 'environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(private oauthService: OAuthService) {
    this.oauthService.configure({
      tokenEndpoint: `https://projects-javaleapprogram.backend.hackathon.lhindts.io/rest/security/login`,
      oidc: false,
    });
  }
}
