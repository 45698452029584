import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthenticationService {
  constructor(private oAuthService: OAuthService) {}

  /**
   * Returns the expiration date of the authentication token
   */
  getTokenExpirationDate(): Date {
    if (!this.oAuthService.getAccessToken()) {
      return null;
    }

    return new Date(this.oAuthService.getAccessTokenExpiration() * 1000);
  }

  login(username: string, password: string) {
    return this.oAuthService.fetchTokenUsingPasswordFlow(username, password);
  }

  logout() {
    this.oAuthService.logOut();
  }

  isLoggedIn() {
    return this.oAuthService.hasValidAccessToken();
  }
}
