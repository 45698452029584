import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { CommonControllerService } from 'generated';

@Injectable()
export class AppService {
  constructor(private commonService: CommonControllerService) {}

  sayHello() {
    return this.commonService
      .getSayHelloUsingGET()
      .pipe(map(response => (response['message'] as unknown) as string));
  }
}
