﻿import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// used to create fake backend
import { fakeBackendProvider } from './_helpers/index';
import { MockBackend, MockConnection } from '@angular/http/testing';
import { BaseRequestOptions } from '@angular/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AlertComponent } from './_directives/index';
import { AuthGuard } from './_guards/index';
import {
  AlertService,
  AuthenticationService,
  UserService,
  AppService,
  CommonService,
} from './_services/index';
import { HomeComponent } from './home/index';
import { AboutComponent } from './about/index';
import { AccountComponent } from './account/index';
import { LoginComponent } from './login/index';
import { RegisterComponent } from './register/index';
import { NavbarModule } from './navbar/index';
import { ApiModule, BASE_PATH } from 'generated';
import { environment } from 'environments/environment';
import { RequestInterceptor } from './utils/request-interceptor';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NavbarModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    ApiModule,
    OAuthModule.forRoot({
      // send access token to API endpoint
      resourceServer: {
        allowedUrls: [environment.loginApiEndpoint],
        sendAccessToken: true,
      },
    }),
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    AboutComponent,
    AccountComponent,
    LoginComponent,
    RegisterComponent,
  ],
  providers: [
    AuthGuard,

    // Service providers
    AlertService,
    AuthenticationService,
    UserService,
    AppService,
    CommonService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    { provide: BASE_PATH, useValue: environment.apiEndpoint },
    { provide: OAuthStorage, useValue: localStorage },

    // providers used to create fake backend
    // fakeBackendProvider,
    MockBackend,
    BaseRequestOptions,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
