﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertService, AuthenticationService } from '../_services/index';

@Component({
  moduleId: module.id,
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  model: { username?: string; password?: string } = {};
  loading = false;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    this.loading = true;
    this.authenticationService
      .login(this.model.username, this.model.password)
      .then(() => {
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      })
      .catch(error => {
        let message = error;
        const apiError = error.error;

        // Check error message from API response
        if (apiError) {
          message = apiError['error-message'];
        }

        this.alertService.error(message);
        this.loading = false;
      });
  }
}
